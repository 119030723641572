import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Header from '../components/header'
import Footer from '../components/footer'
import get from 'lodash/get'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import ScrollFix from '../components/scroll-fix'
import Lightbox from '../components/lightbox'
import projectStyles from './project.module.css'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import { addCloudinaryTransformPreset, mergeCloudinaryContext } from '../lib/util'


class PlayProjectTemplate extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      showLightbox: false,
      currentImageIndex: 0
    }
  }

  showLightbox(index) {
    this.setState({ showLightbox: true, currentImageIndex: index })
  }

  render() {
    const project = get(this.props, 'data.contentfulPlayProject')
    const siteMetadata = get(this, 'props.data.site.siteMetadata')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    const [author] = get(this, 'props.data.allContentfulPerson.edges')
    const mediaContext = get(this, 'props.data.allCloudinaryMedia.edges')
    const { showLightbox, currentImageIndex } = this.state
    let projectAssets = mergeCloudinaryContext(project.images, mediaContext)
    if(project.additionalImages) {
      projectAssets = projectAssets.concat(mergeCloudinaryContext(project.additionalImages, mediaContext))
    }

    const pageDescription = project.description ? documentToPlainTextString(project.description.json) : siteMetadata.description


    return (
      <div>
        <Helmet>
          <title>{`${project.title} | ${siteTitle}`}</title>
          <meta name="description" content={pageDescription} />
          {/*
          <meta property="og:url" content={ogUrl} />
          */}
          <meta property="og:type" content="website" />
          <meta property="og:title" content={`${project.title} | ${siteTitle}`} />
          <meta property="og:description" content={pageDescription} />
          <meta property="og:image" content={addCloudinaryTransformPreset(project.coverImage[0].secure_url, 'og_image')} />
        </Helmet>
        <Header data={author.node} />

        <div className="wrapper">
          <h1>{project.title}</h1>
          <div style={{ position: 'relative' }}>
            <div className="only-desktop">
              <div className={projectStyles.description}>
              {documentToReactComponents(project.description.json)}
              </div>
              {/*
              <ScrollFix offset={0} style={{
                position: 'absolute',
                left: '70%',
                width: '28%',
                paddingLeft: '20px'
              }}>
                {documentToReactComponents(project.description.json)}
              </ScrollFix>
              */}
            </div>
            <div className="only-mobile">
              {documentToReactComponents(project.description.json)}
            </div>

            <div className={projectStyles.mediaList}>
              {projectAssets.map((media, index) => (
                <div key={media.public_id}  onClick={e => this.showLightbox(index)}>
                  <img className={projectStyles.media} alt={media.title} src={addCloudinaryTransformPreset(media.secure_url, 'project_image')} />
                </div>
              ))}
            </div>
          </div>


        </div>
        <Footer data={author.node} />

        {showLightbox &&
          <Lightbox
            assets={projectAssets}
            currentIndex={currentImageIndex}
            onClose={() => {
              this.setState({ showLightbox: false })
            }}
            onNext={() => {
              this.setState({ currentImageIndex: (currentImageIndex + 1) % projectAssets.length })
            }}
            onPrev={() => {
              this.setState({ currentImageIndex: ((currentImageIndex+projectAssets.length) - 1) % projectAssets.length })
            }} />
        }
      </div>
    )
  }
}

export default PlayProjectTemplate

export const pageQuery = graphql`
  query PlayProjectBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
        description
        image
      }
    }

    contentfulPlayProject(slug: { eq: $slug }) {
      title
      coverImage {
        url
        secure_url
      }
      images {
        public_id
        url
        secure_url
      }
      additionalImages {
        public_id
        url
        secure_url
      }
      description {
        json
      }
    }

    allCloudinaryMedia(limit: 500, filter: {public_id: {glob: "PLAY/*"}}) {
      edges {
        node {
          public_id
          context {
            custom {
              alt
              caption
            }
          }
        }
      }
    }

    allContentfulPerson(filter: { contentful_id: { eq: "15jwOBqpxqSAOy2eOO4S0m" } }) {
      edges {
        node {
          name
          instagram
          email
        }
      }
    }
  }
`
